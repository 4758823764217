export default class ExpressForm {
    constructor() {
        this.forms = document.querySelectorAll('.js-express-form');
        if (this.forms) {
            this.initialize();
        }
    }
    initialize = () => {
        this.forms.forEach((form) => {
            let emailField = form.querySelector('input[type=email]');
            if (emailField) {
                emailField.placeholder = 'Email';
            }
            let phoneField = form.querySelector('input[type=tel]');
            if (phoneField) {
                phoneField.placeholder = 'Phone';
            }
            let messageField = form.querySelector('textarea');
            if (messageField) {
                messageField.placeholder = 'Message';
            }
        })
    };
}