export default class Header {
    constructor() {
        // this.body = document.querySelector('body');
        // this.menu = document.querySelector('.js-header-menu');
        // this.menuToggle = document.querySelector('.js-header-menu-toggle');
        this.bindEvents();
    }

    bindEvents = () => {
        // window.addEventListener('load', this.calculateMenuHeight());
        // window.addEventListener('resize', this.calculateMenuHeight());
        // window.addEventListener('orientationchange', this.calculateMenuHeight());
        // this.menuToggle.addEventListener('click', () => {
        //     this.body.classList.toggle('no-overflow');
        //     this.menuToggle.classList.toggle('active');
        //     this.menu.classList.toggle('js-collapsed');
        // });
    };

    calculateMenuHeight = () => {
        this.menu.style.maxHeight = `${window.innerHeight - 110}px`;
    }
}
