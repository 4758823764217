import 'slicknav/jquery.slicknav';
import 'slicknav/scss/slicknav.scss'

export default class Autonav {
    constructor() {
        this.body = document.querySelector('body');
        this.dropdownToggles = document.querySelectorAll("li[class*='has-dropdown']");
        this.initializeNav();
        this.initializeSlickNav();
    }

    initializeNav = () => {
        this.dropdownToggles.forEach((toggle) => {
            let dropdown = toggle.querySelector("ul[class*='dropdown']");
            let dropdownClassName = this.getElementClassName(dropdown);
            let dropdownModifierName = `${dropdownClassName}_visible`;
            toggle.addEventListener('mouseenter', () => {
                dropdown.classList.add(dropdownModifierName);
            });
            toggle.addEventListener('mouseleave', () => {
                dropdown.classList.remove(dropdownModifierName);
            });
        });
    };

    initializeSlickNav = () => {
        $('.autonav').slicknav({
            'appendTo': $('.header'),
            'label': '',
            // afterOpen: () => {
            //     this.toggleBodyOverflow();
            // },
            // afterClose: () => {
            //     this.toggleBodyOverflow();
            // }
        });


    };

    toggleBodyOverflow = () => {
        this.body.classList.toggle('no-overflow');
    };

    getElementClassName = (element) => {
        let className = element.className;
        return className.split(' ')[0];
    };
}
