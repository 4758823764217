import Header from './js/elements/header';
import Autonav from './js/blocks/autonav';
import ExpressForm from './js/blocks/express-form';

export default class App {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initialize();
        });
    }

    initialize = () => {
        this.header = new Header();
        this.autonav = new Autonav();
        new ExpressForm();
    };
}

window.App = new App();